import React, {useEffect, useState} from 'react';
import './css/bootstrap.min.css';
import './css/animate.min.css';
import './css/owl-carousel.min.css';
import './css/meanmenu.min.css';
import './css/cssanimation.min.css';
import './css/default.css';
import './css/style.css';
import './css/responsive.css';
import './css/fontawesome.all.min.css';
import Reservations, {Reservation} from "./components/Reservations";
import { initializeApp } from "firebase/app";
import {collection, getDocs, getFirestore, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from "react-router";
import Admin from "./components/Admin";
import Main from "./components/Main";

const firebaseConfig = {
  apiKey: "AIzaSyAlhmMID0Wflv7-QB5LI-Mp7-zJTaNcxjU",
  authDomain: "countrysidelife-97736.firebaseapp.com",
  projectId: "countrysidelife-97736",
  storageBucket: "countrysidelife-97736.appspot.com",
  messagingSenderId: "430775302860",
  appId: "1:430775302860:web:2b14a6fbe8334b40b26069",
  measurementId: "G-XCG7TC73TC"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const auth = getAuth();

const App = () => {
  const [reservations, setReservations] = useState<Reservation[]>([]);

  useEffect(() => {
    signInAnonymously(auth).then(async () => {
      const collectionRef = collection(db, 'Reservations');
      const unsub = onSnapshot(collectionRef, async () => {
        let q = query(collectionRef);
        // q = query(q, where('startDate', '>', new Date()));
        q = query(q, orderBy('startDate', 'desc'));
        const docs = await getDocs(q);
        setReservations(docs.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.data().name,
            phone: doc.data().phone,
            adventureType: doc.data().adventureType,
            startDate: new Date(doc.data().startDate.seconds * 1000),
            endDate: doc.data().endDate ? new Date(doc.data().endDate.seconds * 1000) : null,
            numberOfAtvs: doc.data().numberOfAtvs ?? 1,
          };
        }));
      })
    });
  }, [])

  return (
      <BrowserRouter>
        <Routes>
          <Route path={'/admin'} element={<Admin reservations={reservations}/>} />
          <Route path={'*'} element={<Main reservations={reservations} />} />
        </Routes>
      </BrowserRouter>
      );
}

export default App;
