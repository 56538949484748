import React from 'react';
import {Box} from "@mui/material";

type Props = {
    img: any;
    title: string;
}

const ImageTitleCard = (props: Props) => {
    return (
        <Box className="col-lg-4 col-sm-4" display={'flex'} justifyContent={'center'}>
            <div className="single-quick-icon text-center">
                <div className="quick-icon-content">
                    <img src={props.img} alt="" style={{height: 55}}/>
                    <p>{props.title}</p>
                </div>
            </div>
        </Box>
    );
}

export default ImageTitleCard;