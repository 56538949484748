import React from 'react';
import {EmailRounded, PhoneRounded} from "@mui/icons-material";
import Slide1 from "../img/pic2-cropped.jpg";
import ImageTitleCard from "./ImageTitleCard";
import Icon1 from "../img/icon/9.png";
import ATV from "../img/icon/ATV2.png";
import Buggy from "../img/icon/Buggy3.png";
import About1 from "../img/about-small.jpg";
import ImageTitleDescriptionCard from "./ImageTitleDescriptionCard";
import Camping from "../img/camping-2.jpg";
import Traseu1 from "../img/traseu-1.jpg";
import ATVN from "../img/atv.jpg";
import SXS from "../img/sxs.jpg";
import ATVC from "../img/atv-copii.jpg";
import {Box} from "@mui/material";
import Reservations, {ReservationsProps} from "./Reservations";
import Footer from "./Footer";
import ATVImageTitleDescriptionCard from "./ATVImageTitleCard";

const Main = ({reservations}: ReservationsProps) => {
    return (
        <div className="App">
            <div className="wrapper">
                <header id="header" className="header-area absolute-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-3">
                            </div>
                            <div className="col-lg-6 col-sm-9">
                                <div className="header-top-contact">
                                    <ul>
                                        <li>
                                            <a href="mailto:countrysidelifero@gmail.com">
                                                <EmailRounded/>
                                                countrysidelifero@gmail.com
                                            </a>
                                        </li>
                                        <li><a href="tel:0723659484"><PhoneRounded/>0723 659 484</a></li>
                                        <li><a href="tel:0254775180"><PhoneRounded/>0254 775 180</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="slider-area">
                    <div id="welcome-slide" className="slide-controls">
                        <div className="single-slide overlay-bg-2">
                            <div className="slide-thumb shape-1" style={{overflow: 'hidden'}}>
                                <img src={Slide1} alt=""/>
                                <div className="slide-content">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10 col-sm-12">
                                                <div className="slide-content-area">
                                                    <div className="single-slide-content text-center">
                                                        <div className="content-text">
                                                            <h1>CountrySide Life</h1>
                                                            <h2>E timpul pentru noi <strong>aventuri</strong>
                                                                <span></span> si <strong>experiente</strong></h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <div className="quick-icon-area pb-90">
                    <div className="container">
                        <div className="row">
                            <ImageTitleCard img={Icon1} title={'Camping'}/>
                            <ImageTitleCard img={ATV} title={'ATV'}/>
                            <ImageTitleCard img={Buggy} title={'SXS'}/>
                        </div>
                    </div>
                </div>
                <div className="about-area pb-115">
                    <div className="about-bg">
                        <img src={About1} alt="" style={{borderRadius: 20}}/>
                    </div>
                    <div className="container" style={{maxWidth: '100%'}}>
                        <div className="row align-content-center">
                            <div className="col"></div>
                            <div className="col-lg-6">
                                <div className="section-title" style={{marginBottom: 15}}>
                                    <h2 style={{marginBottom: 15}}>Incepe-ti aventura<span></span> cu CountrySide Life
                                    </h2>
                                </div>
                                <div className="about-content">
                                    <p style={{marginBottom: 5}}>
                                        Pentru că iubim aventura, ne plac plimbările inedite și suntem dependenți de
                                        adrenalina oferită de o plimbare cu ATV-ul, am decis să oferim acest gen de
                                        distracție și altor iubitori de aventură.
                                    </p>
                                    <p style={{marginBottom: 5}}>
                                        Zona noastră, situată la poalele Munților Retezat, oferă peisaje și trasee
                                        spectaculoase, atât pentru cei care vor să iasă din cotidian, cât și pentru cei
                                        care caută adrenalină. Tot ce trebuie să faci este o programare, online sau
                                        telefonic. Noi te vom aștepta!
                                    </p>
                                    <p style={{marginBottom: 5}}>
                                        Ghidul nostru îți va arăta cum să folosești corect ATV-ul, îți va prezenta
                                        normele de siguranță și te va însoți pe toată durata plimbării.
                                    </p>
                                    <p style={{marginBottom: 5}}>
                                        Traseele noastre sunt în zona comunei Densuș, cu atingerea obiectivelor
                                        turistice și istorice din zonă, fără a încălca suprafețele de teren și drumurile
                                        din ariile protejate aflate în proximitate.
                                    </p>
                                    <p style={{marginBottom: 5}}>
                                        ATENȚIE!!! Nu închiriem ATV-uri. Oferim activități și servicii recreative, fiind
                                        în permanență însoțiți de ghidul nostru.
                                    </p>
                                    <a href="#reservations" className="btn btn-theme">Rezervă acum!</a>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="adventure-area pb-90 pt-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-md-8">
                                <div className="section-title text-center">
                                    <h2 className="title">Ce iti oferim</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ImageTitleDescriptionCard img={Camping} title={'Camping'} description={
                                <>
                                    <p>Punem la dispoziție terenul situat în apropierea locației noastre pentru campare rulote și corturi. Suprafață suficientă pentru până la 10 rulote. Posibilitate racordare la curent electric și apă. Aveți acces la spațiu de gătit, toalete, mașină de spălat, uscător rufe, internet, telefon în cadrul locuinței noastre.
                                    </p>
                                    <p>Obiective turistice apropiate: biserica Densuș, muzeul etnografic Peșteana, valea râului Mare, pensiunile și cabanele din comuna noastră etc.</p>
                                    <p>Prețul folosirii parcelei de parcare este de 35 lei/24h cu 2 persoane inclusiv.</p>
                                    <p>Pentru parcare de durată mai mare, prețul se negociază la instalare. Ne bucurăm să vă avem oaspeți cât mai mult pentru a gusta din plin farmecul vieții la țară.</p>
                                </>
                            }/>
                            <ImageTitleDescriptionCard img={Traseu1} title={'Trasee ATV/SXS'} description={
                                <>
                                    <p>Folosirea ATV-urilor se face pe baza unui act de identitate și permis de conducere categoria B.
                                    </p>
                                    <p>La final ne vom asigura că, respectând indicațiile ghidului, nu au fost aduse prejudicii ATV-urilor folosite.</p>
                                    <p>ATV-ul Hunter de 150cm³ poate fi folosit de către copii, sub supravegherea părinților și a noastră pe circuitul închis din apropierea locației noastre. Acesta este prevăzut cu comandă la distanță pentru reducerea vitezei sau oprirea motorului în caz de nevoie.</p>
                                    <p>Plimbare ATV CF MOTO 450 – 150 RON/h</p>
                                    <p>Plimbare ATV SXS CF MOTO 1000 – 200 RON/h</p>
                                    <p>Plimbare pe circuit închis cu ATV pentru copii – 50 RON/30min</p>
                                    <p>* Combustibilul este inclus în preț.</p>
                                </>
                            }/>
                        </div>
                    </div>
                </div>
                <div className="funfact-area overlay-bg pt-170 pb-70">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-md-8">
                                <div className="section-title white text-center">
                                    <h2 className="title">ATV-urile noastre</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/*<CounterCard title={'Something'} counterValue={'10258 M'}/>*/}
                            {/*<CounterCard title={'Something'} counterValue={'670 +'}/>*/}
                            {/*<CounterCard title={'Something'} counterValue={'910 +'}/>*/}
                            {/*<CounterCard title={'Something'} counterValue={'3000 mp'}/>*/}
                            <ATVImageTitleDescriptionCard
                                img={ATVN}
                                title={'ATV CF MOTO 450 - 32CP'}
                            />
                            <ATVImageTitleDescriptionCard
                                img={SXS}
                                title={'SXS CF MOTO Z1000 - 80CP'}
                            />
                            <ATVImageTitleDescriptionCard
                                img={ATVC}
                                title={'ATV HUNTER 150CC'}
                            />
                        </div>
                    </div>
                </div>

                <Box id={'reservations'} className="partner-area pb-220" paddingTop={'50px'}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-title text-center">
                                    <h2 className="title">Rezerva acum</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">

                            </div>
                        </div>
                    </div>
                    <Reservations reservations={reservations}/>
                </Box>

                <Footer/>
            </div>
            <script src="js/vendor/jquery-3.3.1.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/owlcarousel.min.js"></script>
            <script src="js/meanmenu.min.js"></script>
            <script src="js/counterup.min.js"></script>
            <script src="js/scrollup.min.js"></script>
            <script src="js/waypoints.min.js"></script>
            <script src="js/imagesloaded.pkgd.min.js"></script>
            <script src="js/isotope.min.js"></script>
            <script src="js/main.js"></script>
        </div>
    );
}

export default Main;